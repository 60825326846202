import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import { captureException } from '@sentry/browser'

window.Stimulus = Application.start()
const defaultErrorHandler = window.Stimulus.handleError.bind(window.Stimulus)

window.Stimulus.load(definitionsFromContext(require.context('.', true, /_controller\.js$/)))
window.Stimulus.load(definitionsFromContext(require.context('./forms', true, /_controller\.js$/)))

window.Stimulus.handleError = (error, message, detail = {}) => {
  defaultErrorHandler(error, message, detail)
  captureException(error)
}
