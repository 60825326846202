import { Controller } from '@hotwired/stimulus'
import JustValidate from 'just-validate'

export default class extends Controller {
  connect() {
    const validation = new JustValidate(this.element, {
      errorLabelStyle: {},
      errorLabelCssClass: ['input__error'],
    })

    validation
      .addRequiredGroup('#group', 'You should select at least one reason')
      .addField('#other', [
        {
          rule: 'number',
        },
      ])
      .addField('#other_reason', [
        {
          validator: (value, fields) => {
            if (fields['#other'].elem.checked && value.length === 0) {
              return false
            }

            return true
          },
          errorMessage: 'Please provide other reason',
        },
      ])
      .onSuccess((event) => {
        event.currentTarget.submit()
      })
  }
}
